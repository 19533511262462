import React from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo"
import ProductPageHero from "../../../components/PageComponents/ProductPage/Hero/Hero"
import styled from "@emotion/styled"
import {
  NoCuringPeriodSection,
  RecoSection,
  SafeWithUsSection,
} from "../../../components/PageComponents/IndexPage/DefaultSections/DefaultSections"

const ProduktInsuranceStartPage = () => {
  return (
    <Layout>
      <SEO title="evoli - Produktförsäkring" />

      <ProductPageHero />

      <SubSections>
        <NoCuringPeriodSection />
        <SafeWithUsSection />
        <RecoSection />
      </SubSections>
    </Layout>
  )
}

export default ProduktInsuranceStartPage

const SubSections = styled.div`
  margin-top: 160px;
  margin-bottom: 160px;
  margin: 160px 0;

  @media (max-width: 768px) {
    margin: 60px 0;
  }

  div {
    margin-bottom: 60px;
  }
`
