import React, { useContext } from "react"
import styled from "@emotion/styled"
import { HeroTitle } from "./HeroTitle"
import { HeroParagraph } from "./HeroParagraph"
import { useTheme } from "emotion-theming"
import { CookieJar } from "../../../CookieBar"
import { QuoteContext } from "../../../../Context/QuoteContext"

const ProductPageHero = () => {
  const { state } = useContext(QuoteContext)
  const theme = useTheme()

  return (
    <HeroSection
      bgColor={theme.colors.HeroBackground}
      cookieConsent={state.cookieConsent}
    >
      <HeroContainer>
        <HeroTitle />

        <HeroParagraph />
      </HeroContainer>
      {/* {!state.cookieConsent && <CookieJar />} */}
    </HeroSection>
  )
}
export default ProductPageHero

const HeroSection = styled.section`
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f9f9fb);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 160px 30px 0 30px;

  @media (max-width: 896px) and (max-height: 600px) {
    padding: 60px 40px;
  }

  @media (max-width: 768px) {
    padding: 60px 22px 0 22px;
  }

  @media (max-width: 414px) {
    padding: 60px 16px 0 16px;
  }

  @media (max-width: 375px) {
    padding: 60px 8px 0 8px;
  }

  @media (max-width: 320px) {
    padding: 60px 0 0 0;
  }
`

const HeroContainer = styled.div`
  max-width: 638px;
  display: flex;
  flex-direction: column;
  margin-bottom: 130px;

  @media (max-width: 896px) and (max-height: 600px) {
    margin-bottom: 60px;
  }

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media (max-width: 414px) {
    margin-bottom: 60px;
  }

  @media (max-width: 375px) {
    margin-bottom: 60px;
  }

  @media (max-width: 320px) {
    margin-bottom: 60px;
  }
`
