import React from "react"
import styled from "@emotion/styled"

export const HeroParagraph = () => (
  <Text>
    Vår produktförsäkring erbjuds av samarbetspartners vid köp av produkten. Den
    ger dig som kund ersättning om olyckan skulle vara framme, utan någon
    självrisk eller värdeminskning. Du väljer själv hur länge du vill ha det
    extra skyddet - Försäkringen gäller utan bindningstid. För fullständig
    information, se:
    <br />
    <br />
    <a
      href="/forsakringsvillkor/Produktforsakring_villkor_2021-05-01.pdf"
      target="_blank"
    >
      Försäkringsvillkor 2021-05-01
    </a>
    <br />
    <br />
    <a href="/produktfaktablad\IPID_produkt_2021-05-01.pdf" target="_blank">
      Produktfaktablad (IPID)
    </a>
    <br />
    <br />
    <a
      href="/forkopsinformation\Forkopsinformation-Produktforsakring.pdf"
      target="_blank"
    >
      Förköpsinformation
    </a>
    <br />
    <br />
    Behöver du anmäla en skada? Det gör du enkelt{" "}
    <a
      href="https://support.evoli.se/sv/articles/3778940-anmal-skada"
      target="_blank"
    >
      här
    </a>
    .
  </Text>
)

const Text = styled.p`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: ${props => props.theme.colors.HeroTextBox.color};
  max-width: 558px;
  margin-bottom: 36px;

  a {
    color: #5558ea;
    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 896px) and (max-height: 600px) {
    font-size: 16px;
  }

  @media (max-width: 414px) {
    font-size: 14px;
    margin-bottom: 14px;
    line-height: 1.57;
    max-width: 333px;
  }

  @media (max-width: 375px) {
    padding: 0 7px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
    margin-bottom: 15px;
  }
`
